import { HoldingAreaSlotConfig, TimeSlotConfig } from '@shared/modules/scheduler/models/time-slot/time-slot.models';
import { EmptySlotComponent } from '../components/slot-content/empty-slot/empty-slot.component';
import { Injector } from '@angular/core';
import {
  SchedulerGridEvent,
  SchedulerHoldingAreaEvent,
} from '@shared/modules/scheduler/models/scheduler/scheduler.models';
import { NapaEvent } from '@core/models/entities/event.models';
import { SessionEventComponent } from '../components/slot-content/session-event/session-event.component';
import { SessionSchedulerEvent, SessionSchedulerEventType } from '../models/session-scheduler.models';
import { UnavailableSlotComponent } from '../components/slot-content/unavailable-slot/unavailable-slot.component';
import { BlockedSlotComponent } from '../components/slot-content/blocked-slot/blocked-slot.component';
import { add, format, parse } from 'date-fns';
import { OnHoldEventComponent } from '../components/slot-content/on-hold-event/on-hold-event.component';

export function addHour(time: string): string {
  const [hour, minutes] = time.split(':');

  return `${parseInt(hour) + 1}:${minutes}`;
}

export function subtractHour(time: string): string {
  const [hour, minutes] = time.split(':');

  return `${parseInt(hour) - 1}:${minutes}`;
}

export function addMinutes(time: string, amount: number): string {
  const date = parse(time, 'H:mm', new Date());

  return format(add(date, { minutes: amount }), 'H:mm');
}

export function subtractMinutes(time: string, amount: number): string {
  const date = parse(time, 'H:mm', new Date());

  return format(add(date, { minutes: -amount }), 'H:mm');
}

export function parseTimePoint(time: string): { hours: number; minutes: number } {
  const [hours, minutes] = time.split(':');

  return { hours: parseInt(hours), minutes: parseInt(minutes) };
}

export function hasMinutes(time: string): boolean {
  const { minutes } = parseTimePoint(time);

  return minutes > 0;
}

export function createSchedulerEvent(event: NapaEvent, columnId: string): SchedulerGridEvent<SessionSchedulerEvent> {
  return {
    columnId,
    id: event._id,
    location: 'grid',
    start: event.time_from,
    end: subtractMinutes(event.time_to, 30),
    data: { event, type: SessionSchedulerEventType.RESERVED },
  };
}

export function createOnHoldEvent(event: NapaEvent): SchedulerHoldingAreaEvent<SessionSchedulerEvent> {
  return {
    id: event._id,
    location: 'holding_area',
    data: { event, type: SessionSchedulerEventType.RESERVED },
  };
}

export function createBlockedSlot(event: NapaEvent, columnId: string): SchedulerGridEvent<SessionSchedulerEvent> {
  return {
    columnId,
    id: event._id,
    location: 'grid',
    start: event.time_from,
    end: subtractMinutes(event.time_to, 30),
    data: { event, type: SessionSchedulerEventType.BLOCKED },
  };
}

export function createEmptySlotConfig(injector: Injector): TimeSlotConfig {
  return {
    injector,

    componentType: EmptySlotComponent,

    replaceable: true,

    copyOnDrop: false,

    dragable: false,
  };
}

export function createBlockedSlotConfig(injector: Injector): TimeSlotConfig {
  return {
    injector,

    componentType: BlockedSlotComponent,

    replaceable: false,

    copyOnDrop: true,

    dragable: true,
  };
}

export function createUnavailableSlotConfig(injector: Injector): TimeSlotConfig {
  return {
    injector,

    componentType: UnavailableSlotComponent,

    replaceable: false,

    copyOnDrop: false,

    dragable: false,
  };
}

export function createSessionEventSlotConfig(injector: Injector): TimeSlotConfig {
  return {
    injector,

    componentType: SessionEventComponent,

    replaceable: false,

    copyOnDrop: false,

    dragable: true,
  };
}

export function createOnHoldEventSlotConfig(injector: Injector): HoldingAreaSlotConfig {
  return {
    injector,

    componentType: OnHoldEventComponent,
  };
}
