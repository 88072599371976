import { NapaDialogConfig } from '@shared/modules/dialog/models/dialog.models';

export const CONFIRM_DELETE_ON_HOLD_EVENT_DIALOG_CONFIG: NapaDialogConfig = {
  title: 'Delete On-Hold Event?',
  body: 'Are you sure you want to delete event? This action can not be undone',
  hasPrimaryBtn: true,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
  primaryBtnText: 'Confirm',
};
