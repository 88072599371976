<napa-card>
  <div class="on-hold-event-header">
    <div class="on-hold-event-header__section">{{ event.child?.full_name }}</div>

    <napa-delete-icon (click)="removeEvent()"></napa-delete-icon>
  </div>

  <div class="on-hold-event-header-body">
    <napa-modalities [modalities]="event.modality" [maxLength]="3"></napa-modalities>

    <span class="napa-text-placeholder">{{ event.time_from }}-{{ event.time_to }}</span>
  </div>
</napa-card>
