import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NapaEvent } from '@core/models/entities/event.models';
import { EventsApi } from '@core/services/api/events.api';
import { CardComponent } from '@shared/components/card/card.component';
import { SCHEDULER_EVENT } from '@shared/modules/scheduler/constants/scheduler.constants';
import { GridAdapter } from '@shared/modules/scheduler/models/grid-adaper/grid-adaper.models';
import { SchedulerGridEvent } from '@shared/modules/scheduler/models/scheduler/scheduler.models';
import { SessionSchedulerEvent } from '../../../models/session-scheduler.models';
import { DeleteIconComponent } from '@shared/components/icons/delete-icon/delete-icon.component';
import { EDIT_SCHEDULE_SESSIONS_ROLES } from '@core/constants';
import { CanRenderDirective } from '@shared/directives/can-render.directive';

@Component({
  selector: 'napa-blocked-slot',
  standalone: true,
  imports: [CommonModule, CardComponent, DeleteIconComponent, CanRenderDirective],
  templateUrl: './blocked-slot.component.html',
  styleUrl: './blocked-slot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockedSlotComponent {
  protected event!: NapaEvent;
  readonly UpdateSessionsRoles = EDIT_SCHEDULE_SESSIONS_ROLES;

  constructor(
    private eventsApi: EventsApi,
    private gridAdapter: GridAdapter,
    @Inject(SCHEDULER_EVENT) public schedulerEvent: SchedulerGridEvent<SessionSchedulerEvent>
  ) {
    this.event = this.schedulerEvent.data!.event!;
  }

  remove(): void {
    this.eventsApi.delete(this.event._id).subscribe(() => {
      this.gridAdapter.removeOne(this.event._id);
    });
  }
}
