import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CardComponent } from '@shared/components/card/card.component';
import { SchedulerHoldingAreaEvent } from '@shared/modules/scheduler/models/scheduler/scheduler.models';
import { DeleteIconComponent } from '@shared/components/icons/delete-icon/delete-icon.component';
import { SCHEDULER_EVENT } from '@shared/modules/scheduler/constants/scheduler.constants';
import { ModalitiesComponent } from '@shared/components/modalities/modalities.component';
import { SessionSchedulerEvent } from '../../../models/session-scheduler.models';
import { NapaEvent } from '@core/models/entities/event.models';
import { EventsApi } from '@core/services/api/events.api';
import { DialogApi } from '@shared/modules/dialog/services/dialog.api';
import { NapaDialogCloseEvent } from '@shared/modules/dialog/models/dialog.models';

import { CONFIRM_DELETE_ON_HOLD_EVENT_DIALOG_CONFIG } from '../../../constants/on-hold-event.contants';
import { HoldingAreaAdapter } from '@shared/modules/scheduler/models/holding-area/holding-area-adapter.models';
import { catchError, filter, map, switchMap, throwError } from 'rxjs';
import { ApiError } from '@core/models/http.models';

@Component({
  selector: 'napa-on-hold-event',
  standalone: true,
  imports: [CommonModule, CardComponent, ModalitiesComponent, DeleteIconComponent],
  templateUrl: './on-hold-event.component.html',
  styleUrl: './on-hold-event.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnHoldEventComponent {
  event!: NapaEvent;

  constructor(
    private eventsApi: EventsApi,
    private dialogApi: DialogApi,
    private holdingAreaAdapter: HoldingAreaAdapter,
    @Inject(SCHEDULER_EVENT) public schedulerEvent: SchedulerHoldingAreaEvent<SessionSchedulerEvent>
  ) {
    this.event = schedulerEvent.data.event!;
  }

  removeEvent(): void {
    this.dialogApi
      .openGenericPopup(CONFIRM_DELETE_ON_HOLD_EVENT_DIALOG_CONFIG)
      .afterClosed()
      .pipe(map((event?: NapaDialogCloseEvent) => !!event?.primaryBtnClicked))
      .pipe(
        filter(Boolean),
        switchMap(() => {
          this.holdingAreaAdapter.removeOne(this.schedulerEvent.id);

          return this.eventsApi.delete(this.event._id);
        })
      )
      .pipe(
        catchError((error: ApiError) => {
          this.holdingAreaAdapter.addOne(this.schedulerEvent);

          return throwError(() => error);
        })
      )
      .subscribe();
  }
}
