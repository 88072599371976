<napa-generic-dialog>
  <napa-dialog-header>
    <ng-container napaCustomDialogContent>
      <h4 class="napa-medium-dialog-title assign-kid-dialog-title">
        Edit Schedule of {{ sessionEvent.first_name }} {{ sessionEvent.last_name }}
      </h4>
    </ng-container>
  </napa-dialog-header>

  <napa-dialog-body>
    <ng-container napaCustomDialogContent>
      <form [formGroup]="form">
        <wlcm-form-field>
          <wlcm-label>Client</wlcm-label>

          <wlcm-autocomplete
            wlcmInput
            napaTrackReactiveControl
            formControlName="child"
            [fetchPaginatedOptions]="fetchKidOptions"
            (selected)="handleSelectedChild($event)"
            selectOptionFormat="CompleteOption"
            placeholder="Not selected"
            #sessionNameAutocomplete
          ></wlcm-autocomplete>

          <napa-arrow-down-blue-icon wlcmFormFieldSuffix></napa-arrow-down-blue-icon>
        </wlcm-form-field>

        <div class="time-fields-section" napaTimeRangeManager #timeRangeManager="NapaTimeRangeManager">
          <wlcm-form-field>
            <wlcm-label>Start time</wlcm-label>

            <napa-clock-icon wlcmFormFieldPrefix></napa-clock-icon>

            <wlcm-select
              wlcmInput
              napaStartTime
              formControlName="time_from"
              [options]="startTimeOptions"
              (selectionChange)="handleStartTimeChange($event)"
              #startTime
            ></wlcm-select>
          </wlcm-form-field>

          <wlcm-form-field>
            <wlcm-label>End time</wlcm-label>

            <napa-clock-icon wlcmFormFieldPrefix></napa-clock-icon>

            <wlcm-select
              wlcmInput
              napaEndTime
              formControlName="time_to"
              [options]="endTimeOptions"
              (selectionChange)="handleEndTimeChange($event)"
              #endTime
            ></wlcm-select>
          </wlcm-form-field>
        </div>

        <wlcm-form-field>
          <wlcm-label>Modality</wlcm-label>

          <wlcm-select
            wlcmInput
            formControlName="modality"
            [options]="modalityOptions()"
            [multiple]="true"
          ></wlcm-select>
        </wlcm-form-field>

        <h4 class="recommended-program-title">Recommended therapy program</h4>
      </form>
    </ng-container>
  </napa-dialog-body>

  <napa-dialog-footer>
    <ng-container napaCustomDialogContent>
      <div class="assign-kid-dialog-actions">
        <wlcm-button type="borderless" (clicked)="close()">Cancel</wlcm-button>

        <wlcm-button [isLoading]="(loading$ | async)!" (clicked)="submit()">Update Schedule</wlcm-button>
      </div>
    </ng-container>
  </napa-dialog-footer>
</napa-generic-dialog>
