import { Signal } from '@angular/core';
import { SchedulerEventId, SchedulerHoldingAreaEvent } from '../scheduler/scheduler.models';

export abstract class HoldingAreaAdapter<T = unknown> {
  abstract events: Signal<SchedulerHoldingAreaEvent<T>[]>;

  abstract setAll(events: SchedulerHoldingAreaEvent<T>[]): void;

  abstract addOne(event: SchedulerHoldingAreaEvent<T>): void;

  abstract updateOne(id: SchedulerEventId, data: Partial<T>): void;

  abstract removeOne(id: SchedulerEventId): void;
}
